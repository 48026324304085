/*
 * @Descripttion: *
 * @version: *
 * @Author: Mingkit
 * @Date: 2020-11-18 20:58:12
 * @LastEditors: Mingkit
 * @LastEditTime: 2020-11-25 17:42:43
 */
import CustomTable from './CustomTable'
export default {
  name: 'AdminCustom',
  components: {
    CustomTable
  },
  methods: {
    async add () {
      const data = await this.$wPost('/operate/custom/check_custom_count.do')
      if (data === true) {
        this.$router.push({ path: '/operate/custom/add' })
      }
      this.$refs.submitButton.updatedLoading(false)
    }
  }
}
